<template>
    <div>
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('钱包地址绑定')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="wrapper">
            <div class="bs-panel">
                <div class="cur-card">USDT</div>
            </div>
            <div class="bs-panel">
                <div class="bs-panel_top">{{active}}</div>
                <van-form class="bs-form">
                    <van-field v-model="usdt_address" :placeholder="$t('请输入钱包地址')">
                    </van-field>
                    <div style="word-break: break-all;padding: 10px 20px;text-align: left;line-height: 20px;font-size: 12px">
                        {{usdt_address}}
                    </div>
                    <div class="text-gray text-tip">{{$t('钱包地址提现绑定说明')}}</div>
                </van-form>
            </div>
            <div class="wt-tips">
                <div class="wt-label text-green">{{$t('绑定提示')}}</div>
                <div class="wt-body text-gray" v-if="this.$t('绑定提示1') != '绑定提示1'" v-html="$t('绑定提示1')"></div>
                <div class="wt-body text-gray" v-if="this.$t('绑定提示2') != '绑定提示2'" v-html="$t('绑定提示2')"></div>
                <div class="wt-body text-gray" v-if="this.$t('绑定提示3') != '绑定提示3'" v-html="$t('绑定提示3')"></div>
                <div class="wt-body text-red" v-if="this.$t('绑定提示4') != '绑定提示4'" v-html="$t('绑定提示4')"></div>
            </div>
            <div class="btn-space btn-fixed">
                <van-button type="primary" @click="submit" block round>{{$t('确认')}}</van-button>
            </div>
        </div>


    </div>
</template>

<script>
    import helper from "../../utils/helper";

    export default {
        components: {},
        data() {
            return {
                id: 0,
                active: 'TRC20',
                tkvalue: 'USDT',
                value: '',
                radio: '1',
                list: [
                    'TRC20',
                    'ERC20',
                ],
                usdt_address: '',
            }
        },
        methods: {
            changeTab() {
                if (this.id == 0) {
                    this.usdt_address = '';
                }

            },
            submit() {
                this.loading = true;
                let loading = this.$toast.loading();
                let url = '/bank/save_usdt';
                let _data = {
                    id: this.id,
                    type: 1,
                    bank_name: this.active,
                    number: this.usdt_address,
                };
                let data = helper.encrypt(JSON.stringify(_data));
                this.$axios
                    .post(
                        url,
                        this.$qs.stringify({
                            data: data,
                        })
                    )
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.$toast.success(res.data.msg);
                            this.$router.push({path: '/bank'})
                        } else {
                            this.$toast.fail({duration: 3000, message: res.data.msg});
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(err.message);
                    })
                    .then(() => {
                        // loading.clear();
                        this.loading = false;
                    });
            },
            getData() {
                this.$axios.get(this.$api_url.get_bind_bank + '?id=' + this.id).then((res) => {
                    let data = res.data;
                    if (data.code == 0) {
                        let type = data.data.info.type ?? 0;
                        if (type == 1) {
                            this.active = data.data.info.bank_name;
                            console.log(this.active);
                            this.usdt_address = data.data.info.number;
                        }
                    }
                }).catch((err) => {
                    this.$toast.fail(err.message);
                });
            },
            onClickLeft() {
                this.$router.go(-1)
            },
        },
        created() {
            if (this.$route.query.id !== undefined) {
                this.id = this.$route.query.id;
            }
            if (this.$route.query.channel !== undefined) {
                this.active = this.$route.query.channel;
            }
            this.getData();
        }

    }
</script>

<style scoped="scoped">
    .tri-cellss {
        font-size: 14px;
        padding: 0 0 20px 0;
    }
    .wt-tips{
        padding:15px 0;
        font-size: 14px;
    }
    .wt-tips .wt-label{
        margin-bottom: 6px;
    }

</style>